var PageApp = new Backbone.Marionette.Application()

if (!window.bidjsHooks) {
  window.bidjsHooks = {}
}

// polyfill for Object assign
if (typeof Object.assign !== 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, 'assign', {
    value: function assign (target, varArgs) { // .length of function is 2
      'use strict'
      if (target === null) { // TypeError if undefined or null
        throw new TypeError('Cannot convert undefined or null to object')
      }

      var to = Object(target)

      for (var index = 1; index < arguments.length; index++) {
        var nextSource = arguments[index]

        if (nextSource !== null) { // Skip over if undefined or null
          for (var nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey]
            }
          }
        }
      }
      return to
    },
    writable: true,
    configurable: true
  })
}

// function name space
PageApp.Ent = {}
PageApp.Views = {}
PageApp.Controllers = {}

// instance name space
var myApp = PageApp // eslint-disable-line no-global-assign
myApp.utils = {}
myApp.ent = {}
myApp.views = {}
myApp.controllers = {}
PageApp.addRegions({
  gritterRegion: '#gritter',
  mainRegion: '#bidlogix-app',
  myModalRegion: '#bidlogix-modal'
})

PageApp.on('before:start', function () {
  this.utils.ajax = new PageApp.AjaxUtils()
  this.utils.types = new PageApp.TypeUtils()
  this.utils.token = new PageApp.TokenUtils()
  this.utils.webrtc = new PageApp.WebrtcUtils()
  this.utils.errors = new PageApp.ErrorUtils()
  this.utils.validation = new PageApp.ValidationUtils()
  this.ent.user = new PageApp.Ent.User({ user: 'noone' })
})

PageApp.on('start', function (clientModel) {
  if (window.bidjs.callback) {
    window.bidjs.callback({
      action: 'BIDJS_INITIALISED'
    })
  }
  if (!clientModel) {
    clientModel = window.bidjs
  }
  this.router = new Marionette.AppRouter({
    controller: this,
    appRoutes: {}
  })
  this.registerHelpers()
  // we instantiate the loginRouter before our other routes, since this handles any existing authentication, which needs to be handled before our /models request
  this.controllers.loginRouter = new PageApp.Controllers.LoginRouter()
  this.controllers.loginRouter.initialize()

  this.refreshModels({ clientModel: clientModel,
    callback: _.bind(function (models) {
      this.startServices(models)
      Backbone.history.start()
      if (myApp.ent.user.isLoggedIn()) {
        myApp.vent.trigger('authentication:success', models.userModel, true)
      } else {
        myApp.vent.trigger('authentication:remove', true)
      }
      if (window.bidjs.callback) {
        window.bidjs.callback({
          action: 'BIDJS_LOADED'
        })
      }
    }, this) })
})

myApp.refreshModels = function (config) {
  if (config && config.clientModel) {
    var clientModel = config.clientModel
    if (window.document.documentMode) { // if IE
      var unsupportedBrowserMessage = (clientModel.options && clientModel.options.unsupportedBrowserMessage) || 'Your browser is not supported. Please use a browser such as Google Chrome or Microsoft Edge to ensure full functionality'
      window.alert(unsupportedBrowserMessage)
    }
    this.ent.config = new PageApp.Ent.Config(clientModel)
  }

  if (!this.ent.config) {
    console.error('Could not load configuration')
    return
  }

  this.ent.config.set('contextPath', '#!')
  this.ent.config.set('referer', this.ent.config.getReferer())

  const callback = _.bind(function (models) {
    if (!models || models.failed) {
      if (models.error === 'invalid_token') {
        console.warn('Invalid token, logging out')
        myApp.vent.trigger('authentication:remove', false)
        return myApp.refreshModels(config)
      }
      return myApp.vent.trigger('ui:notification', { sticky: true, text: 'Server error - please check the configuration: ' + this.ent.config.attributes, level: 'danger' })
    } else {
      this.setModels(models)
      if (config && config.callback) {
        config.callback(models)
      }
    }
  }, this)

  function fetchModels () {
    return myApp.utils.ajax.get(null, myApp.utils.ajax.getApiEndpoint('models'), callback)
  }

  fetchModels()
}

window.bidjsHooks.forceModelRefresh = function () {
  myApp.refreshModels({ callback: function () {} })
}

myApp.setModels = function (models) {
  if (!models) { return }
  if (models.metaModel) {
    if (!this.ent.metaModel) { this.ent.metaModel = new PageApp.Ent.BasicEntity() }
    this.ent.metaModel.set(models.metaModel)
  }
  if (models.headerModel) {
    if (!this.ent.header) { this.ent.header = new PageApp.Ent.Header() }
    this.ent.header.set(models.headerModel)
  }
  if (models.webAppModel) {
    if (!this.ent.webApp) { this.ent.webApp = new PageApp.Ent.WebApp() }
    this.ent.webApp.set(models.webAppModel)
  }
  var configModel
  if (models.configModel) {
    configModel = (this.ent.config) ? Object.assign({}, this.ent.config.attributes, models.configModel) : models.configModel
    this.ent.config = new PageApp.Ent.Config(configModel)
  }
  if (models.serverConfigModel) {
    configModel = (this.ent.config) ? Object.assign({}, this.ent.config.attributes, models.serverConfigModel) : models.serverConfigModel
    this.ent.config = new PageApp.Ent.Config(configModel)
  }
  if (models.userModel) {
    if (!this.ent.user) { this.ent.user = new PageApp.Ent.User() }
    this.ent.user.set(models.userModel)
  }
  if (models.statusModel) {
    if (!this.ent.status) { this.ent.status = new PageApp.Ent.Status() }
    this.ent.status.set(models.statusModel)
  }
  this.ent.fourOFour = new PageApp.Ent.BasicEntity()
  if (models.fourOFour) {
    this.ent.fourOFour.set(models.fourOFour)
  }
}

myApp.startServices = function (models) {
  this.controllers.i16Strings = new PageApp.Controllers.I16StringsRouter()
  this.controllers.i16Strings.initialize(models.stringsModel.i16Strings)
  this.controllers.application = new PageApp.Controllers.ApplicationRouter()
  this.controllers.application.initialize()

  this.controllers.referenceRouter = new PageApp.Controllers.ReferenceRouter()
  this.controllers.referenceRouter.initialize()
  this.controllers.addressRouter = new PageApp.Controllers.AddressRouter()
  this.controllers.addressRouter.initialize()
  this.controllers.contactSellerRouter = new PageApp.Controllers.ContactSellerRouter()
  this.controllers.contactSellerRouter.initialize()
  this.controllers.makeOfferRouter = new PageApp.Controllers.MakeOfferRouter()
  this.controllers.makeOfferRouter.initialize()
  this.controllers.saleInfoRouter = new PageApp.Controllers.SaleInfoRouter()
  this.controllers.saleInfoRouter.initialize()
  this.controllers.buyNowRouter = new PageApp.Controllers.BuyNowRouter()
  this.controllers.buyNowRouter.initialize()
  this.controllers.transferRouter = new PageApp.Controllers.TransferRouter()
  this.controllers.transferRouter.initialize()
  this.controllers.tenderRouter = new PageApp.Controllers.TenderRouter()
  this.controllers.tenderRouter.initialize()
  this.controllers.timedRouter = new PageApp.Controllers.TimedRouter()
  this.controllers.timedRouter.initialize()
  this.controllers.myBidsRouter = new PageApp.Controllers.MyBidsRouter()
  this.controllers.myBidsRouter.initialize()
  this.controllers.landingPages = new PageApp.Controllers.LandingPageRouter()
  this.controllers.landingPages.initialize()
  this.gritterComponent.start()

  var pathname = window.location.hash
  if (pathname && pathname.indexOf('#') === 0) {
    pathname = pathname.substring(1)
  }
  if (pathname === '') {
    pathname = 'home'
  }
  this.router.on(pathname, function (e) {
    console.log(e)
  })
  this.router.navigate(pathname, { trigger: true })
}
myApp.registerHelpers = function () {
  HandlebarsIntl.registerWith(Handlebars)

  Handlebars.registerHelper('cloudify', function (imageModel, dims) {
    try {
      var DEFAULT_OP = 'fill'
      var width = dims.width ? dims.width : ''
      var height = dims.height ? dims.height : ''
      var operation = myApp.ent.config.get('itemImageTransformOperation') || DEFAULT_OP

      var validOps = [ 'scale', 'fit', 'limit', 'mfit', 'fill', 'lfill', 'pad', 'lpad', 'mpad', 'crop', 'thumb', 'imagga_crop' ]
      if (validOps.indexOf(operation) < 0) {
        operation = DEFAULT_OP
      }
      var quality = 'auto'
      return myApp.utils.types.toCloudinaryImageSrc(imageModel, width, height, quality, operation)
    } catch (err) {
      console.error(err)
    }
  })
}

window.addEventListener('DOMContentLoaded', function () {
  (function ($) {
    $(document).ready(function () {
      PageApp.start()
    })
  })(jQuery)
})
