PageApp.TokenUtils = function () {
  const tokenName = 'bidJSToken' + window.bidjs.config.clientId

  return {
    getToken: function () {
      const token = window.localStorage.getItem(tokenName)
      return (token) ? JSON.parse(token) : false
    }
  }
}
