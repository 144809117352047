PageApp.Views.LandingLayout = Backbone.Marionette.LayoutView.extend({
  getTemplate: function () {
    return hbs.templates.landingLayout
  },
  initialise: function (options) {
    this.options = options
  },
  regions: {
    landingBannerRegion: '#landingBannerRegion',
    searchInputRegion: '#landingSearchInputRegion',
    layoutControlRegion: '#layoutControlRegion',
    searchFiltersRegion: '#searchFiltersRegion',
    pagingTopRegion: '#pagingTopRegion',
    biddingRegion: '#biddingRegion',
    pagingBottomRegion: '#pagingBottomRegion',
    saleInfoRegion: '#saleInfoRegion'
  },
  onRender: function () {
    if (this.model.supportsSearch()) {
      myApp.vent.trigger('search:render', { searchInputRegion: this.getRegion('searchInputRegion'), model: this.model })
    }
    if (myApp.ent.config.attributes.page !== 'itemland') {
      this.layoutControlRegion.show(new PageApp.Views.ControlLayout({ model: new PageApp.Ent.LayoutControl({ parameters: this.model.get('parameters') }) }))
    }
    if (this.model.supportsBanner()) {
      this.options.otherModels.landingBannerModel.set('totalNumberOfMatches', this.model.get('currentFilterModel').totalNumberOfMatches)
      if (myApp.ent.config.attributes.page === 'auctionland') {
        this.options.otherModels.landingBannerModel.set('webcast', this.model.get('saleInfoModel').webcast)
        this.options.otherModels.landingBannerModel.set('auctionDescription', this.model.get('saleInfoModel').description)
        this.options.otherModels.landingBannerModel.set('auctionStartDate', this.model.get('saleInfoModel').auctionStartDatePart)
        this.options.otherModels.landingBannerModel.set('auctionStartTime', this.model.get('saleInfoModel').auctionStartTimePart)
        this.options.otherModels.landingBannerModel.set('auctionEndDate', this.model.get('saleInfoModel').auctionDatePart)
        this.options.otherModels.landingBannerModel.set('auctionEndTime', this.model.get('saleInfoModel').auctionTimePart)
      }
      this.getRegion('landingBannerRegion').show(new PageApp.Views.LandingBannerView({ model: this.options.otherModels.landingBannerModel }))
    }
    if (this.model.get('saleInfoModel')) {
      myApp.ent.saleInfo = new PageApp.Ent.BasicEntity(this.model.get('saleInfoModel'))
      myApp.ent.saleInfo.set('source', 'landing')
    }
    if (this.model.get('showFilters')) {
      myApp.vent.trigger('search:filters', { searchFiltersRegion: this.getRegion('searchFiltersRegion'), model: this.model })
    }
    if (this.model.supportsPaging()) {
      this.model.get('pagingModel').paginationPosition = 'top'
      this.getRegion('pagingTopRegion').show(new PageApp.Views.PagingView({ model: this.model }))

      this.model.get('pagingModel').paginationPosition = 'bottom'
      this.getRegion('pagingBottomRegion').show(new PageApp.Views.PagingView({ model: this.model }))
    }

    // check timedModels as the timed models that came down with the page may be stale
    if (this.options.otherModels.timedModels) {
      myApp.vent.trigger('timed:display', { region: this.getRegion('biddingRegion'), model: this.options.otherModels.timedModels })
    } else {
      myApp.vent.trigger('timed:display', { region: this.getRegion('biddingRegion') })
    }
  }
})
