PageApp.Ent.Config = Backbone.Model.extend({
  initialize: function (options) {
    this.set('containerClass', 'container')
    this.set('serverVsClientTimeOffset', new Date().getTime() - this.get('serverTimestamp'))
    this.set('confirmAmountThresholdMultiplier', 20)

    let clientId = options.config.clientId
    if (clientId && clientId.indexOf('/') === -1) {
      clientId = `/${clientId}`
    }
    var stagingSuffix = (options.config.isStaging && options.config.region !== 'local') ? '.staging' : ''
    var stackURL = '//' + options.config.server + '.' + options.config.region + stagingSuffix + '.bidjs.com'
    this.set('apiBase', stackURL + '/auction-007/api')
    this.set('clientId', clientId)
    this.set('staticBase', stackURL + '/static/4')
    this.set('googleMapsApiKey', options.config.googleMapsApiKey)
  },
  defaults: {
    containerClass: 'container',
    protocol: 'https'
  },
  getNormalisedTimeMillis: function () {
    return new Date().getTime() - this.get('serverVsClientTimeOffset')
  },
  getReferer: function () {
    if (this.get('referer')) {
      return this.get('referer')
    } else {
      return '#!/home'
    }
  },
  getMapsAPIKey: function () {
    return this.get('googleMapsApiKey')
  }
})

PageApp.Ent.PublicStaticLabels = Backbone.Model.extend({})
