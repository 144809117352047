PageApp.Controllers.LandingPageRouter = function (config) {
  var ent = {}
  function initCommands () {
  }
  function initEvents () {
    myApp.vent.on('layoutcontrol:display', function () {
      var page = myApp.ent.config.get('page')
      if (page === 'auctionland' || page === 'marketland' || page === 'searchland') {
        myApp.vent.trigger('landing:display', {})
      }
    }, this)
    myApp.vent.on('layoutcontrol:toggle:open', function (config) {
      ent.searchFilters.get('parameters').parameters.showOpenOnly = config.showOpenOnly
      ent.searchFilters.get('parameters').parameters.page = 0
      var link = ent.searchFilters.getLink('showOpenOnly', config.showOpenOnly)
      myApp.utils.ajax.relocate(link)
    }, this)
    myApp.vent.on('landing:display', function (config) {
      var landingModel = config.landingModel
      if (landingModel) {
        this.landingModel = config.landingModel
      } else if (this.landingModel) {
        // the timedModels should be considered stale in this case and this tells the
        // timed bidding code to use its own cached model.
        landingModel = this.landingModel
        landingModel.timedModels = undefined
      }

      var auctioneer
      if (landingModel.auction && landingModel.auction.auctioneer) {
        auctioneer = new PageApp.Ent.BasicEntity(landingModel.auction.auctioneer)
      }
      ent.searchFilters = new PageApp.Ent.SearchFilters(landingModel.filterBagModel)
      var showFilters = myApp.ent.user.getPreference('showFilters', { value: false })
      var showSaleInfoInPage = myApp.ent.webApp.get('showSaleInfoInPage')
      try {
        if (typeof showFilters === 'undefined') {
          showFilters = !!showSaleInfoInPage
          myApp.ent.user.setPreference('showFilters', showFilters)
        }
      } catch (err) {
        console.log('Recoverable error setting show filters. ' + err)
      }

      var itemCount = myApp.request('reqres:timed:fetch:count')
      if (landingModel.timedModels) {
        if (_.size(landingModel.timedModels.TimedListModel.biddingItems) === 0) {
          showFilters = true
          myApp.ent.user.setPreference('showFilters', showFilters)
        }
      } else {
        if (typeof itemCount !== 'undefined' && itemCount === 0) {
          showFilters = true
          myApp.ent.user.setPreference('showFilters', showFilters)
        }
      }

      try {
        if (typeof showSaleInfoInPage === 'undefined') {
          showSaleInfoInPage = true
        }
      } catch (err) {
        console.log('Recoverable error setting show sale info in page. ' + err)
      }

      if (showSaleInfoInPage) {
        if (typeof myApp.ent.user.getPreference('saleInfoActive') === 'undefined') {
          myApp.ent.user.setPreference('saleInfoActive', 'active')
          myApp.ent.user.setPreference('filtersActive', '')
        }

        // If GE force sale info/filters to display.
        var auctionPath = myApp.ent.config.get('contextPath')
        if (auctionPath === '/auction-028' ||
          auctionPath === '/auction-029' ||
          auctionPath === '/auction-030') {
          showFilters = true
          myApp.ent.user.setPreference('showFilters', showFilters)
        }

        if (myApp.ent.config.get('page') === 'marketland' || myApp.ent.config.get('page') === 'searchland') {
          showFilters = true
          myApp.ent.user.setPreference('showFilters', showFilters)
          myApp.ent.user.setPreference('saleInfoActive', '')
          myApp.ent.user.setPreference('filtersActive', 'active')
        }
      }

      myApp.ent.user.setPreference('showSaleInfoInPage', showSaleInfoInPage)
      ent.searchFilters.set('showFilters', showFilters)

      var otherModels = {
        auctionModel: new PageApp.Ent.BasicEntity(landingModel.auction),
        landingBannerModel: new PageApp.Ent.BasicEntity(landingModel.landingBannerModel),
        searchResults: new PageApp.Ent.BasicEntity(landingModel.searchResultsDto),
        sessionUserAddresses: new PageApp.Ent.BasicEntity(landingModel.userViewDto),
        auctioneer: auctioneer,
        timedModels: landingModel.timedModels
      }

      myApp.mainRegion.show(new PageApp.Views.LandingLayout({ model: ent.searchFilters, otherModels: otherModels }))
    }, this)
  }
  return {
    initialize: function (models) {
      initEvents()
      initCommands()
    }
  }
}
