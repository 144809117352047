PageApp.Views.ControlLayout = Marionette.LayoutView.extend({
  getTemplate: function () {
    return hbs.templates.controlLayout
  },
  initialize: function (options) {
    this.isShowOpenDisabled = options.isShowOpenDisabled
  },
  events: {
    'click .x-layout': 'handleChangeLayout',
    'click .x-filter': 'handleChangeFilter',
    'click .x-saleinfo-modal': 'handleSaleInfoShow',
    'click .x-landing-sale-info-toggle': 'handleSaleInfoInPageShow',
    'click .x-landing-filter-toggle': 'handleFiltersShow',
    'click .x-ta-find-by': 'handleChangeFindBy',
    'click .x-ta-show-open': 'handleShowOpenOnly',
    'submit .x-ta-filters': 'handleSubmit'
  },
  serializeData: function () {
    var landingFilterToggles = false
    var saleinfoBtnClazz, filterBtnClazz
    var showFilters = myApp.ent.user.getPreference('showFilters')
    var showSaleInfoInPage = myApp.ent.user.getPreference('showSaleInfoInPage')
    var filtersChevronDirection = 'down'
    var saleInfoChevronDirection = 'down'

    saleinfoBtnClazz = ''
    filterBtnClazz = 'active'
    if (!showFilters) {
      filterBtnClazz = ''
    }
    var page = myApp.ent.config.get('page')
    var isAuctionLand = (page === 'auctionland')

    if (page === 'auctionland' || page === 'marketland' || page === 'searchland') {
      landingFilterToggles = true
    }
    var saleInfoFilterToggles = false
    if (page === 'auctionland') {
      saleInfoFilterToggles = true
    }
    var gotoItemFeature = false
    var mybidsFilterToggles = false
    var mybidsFilterSet = myApp.ent.status.getMyBidsFilterSet()
    if (page === 'myBidsLand' && mybidsFilterSet) {
      mybidsFilterToggles = true
    } else if (page === 'auctionland') {
      gotoItemFeature = true && !myApp.ent.webApp.get('hideFindBySearch')
    }
    var layout = myApp.ent.user.getPreference('layoutForBidding')
    var listBtnClazz
    var gridBtnClazz = ''
    if (layout === 'grid') {
      gridBtnClazz = 'active'
    } else {
      listBtnClazz = 'active'
      landingFilterToggles = false
    }

    // If GE force sale info/filters to display and hide the auction information button.
    var auctionPath = myApp.ent.config.get('contextPath')
    if (auctionPath === '/auction-028' ||
      auctionPath === '/auction-029' ||
      auctionPath === '/auction-030') {
      landingFilterToggles = false
    }

    var showOpenOnlyChecked = (this.model.get('parameters').parameters.showOpenOnly) ? ' checked ' : ''
    if (this.model.get('parameters').parameters.showOpenOnly) {
      landingFilterToggles = false
    }

    var saleInfoActive = ''
    var filtersActive = ''
    if (showFilters && myApp.ent.user.getPreference('saleInfoActive') === 'active') {
      saleInfoActive = 'active'
      saleInfoChevronDirection = 'up'
    }
    if (showFilters && myApp.ent.user.getPreference('filtersActive') === 'active') {
      filtersActive = 'active'
      filtersChevronDirection = 'up'
    }

    var content = {
      gotoItemFeature: gotoItemFeature,
      landingFilterLabel: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_TIMED_FILTERS'),
      landingFilterToggles: landingFilterToggles,
      saleInfoLabel: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_TIMED_SALEINFO'),
      saleInfoFilterToggles: saleInfoFilterToggles,
      showLayoutToggle: true,
      mybidsFilterToggles: mybidsFilterToggles,
      mybidsFilterSet: mybidsFilterSet,
      listBtnClazz: listBtnClazz,
      gridBtnClazz: gridBtnClazz,
      listView: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_UPCOMING_AUCTIONS_LIST_VIEW'),
      gridView: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_UPCOMING_AUCTIONS_GALLERY_VIEW'),
      auctionInfoLabel: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTION_INFORMATION'),
      saleinfoBtnClazz: saleinfoBtnClazz,
      filterBtnClazz: filterBtnClazz,
      showOpenOnlyChecked: showOpenOnlyChecked,
      showSaleInfoInPage: showSaleInfoInPage,
      saleInfoChevronDirection: saleInfoChevronDirection,
      filtersChevronDirection: filtersChevronDirection,
      isAuctionLand: isAuctionLand,
      saleInfoActive: saleInfoActive,
      filtersActive: filtersActive,
      showFilters: showFilters,
      lotLayout: layout,
      isShowOpenDisabled: this.isShowOpenDisabled
    }
    if (this.model.get('findBy') !== 'lotNumber') {
      content.findByLabel = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_TIMED_GOTO_BY_TITLE')
    } else {
      content.findByLabel = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_TIMED_GOTO_BY_LOTNUMBER')
    }

    content.JspPublicCodes_JSP_TIMED_GOTO_BY_LOTNUMBER = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_TIMED_GOTO_BY_LOTNUMBER')
    content.JspPublicCodes_JSP_TIMED_GOTO_BY_TITLE = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_TIMED_GOTO_BY_TITLE')
    content.JspPublicCodes_JSP_TIMED_GOTO_LABEL = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_TIMED_GOTO_LABEL')
    content.JspPublicCodes_JSP_TIMED_GOTO_ITEM_PH = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_TIMED_GOTO_ITEM_PH')
    content.JspPublicCodes_JSP_TIMED_SHOW_OPEN_LABEL = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_TIMED_SHOW_OPEN_LABEL')

    content = _.extend(content, this.model.attributes)
    return content
  },
  onRender: function () {
    var $input = this.$el.find('.x-typeahead-item')
    this.model.initTypeahead('items', $input)
  },
  handleChangeFindBy: function (e) {
    e.preventDefault()
    this.model.set('findBy', $(e.currentTarget).data('findby'))
    this.model.set('findByPh', $(e.currentTarget).data('findbyph'))
    this.render()
    return false
  },
  handleShowOpenOnly: function (e) {
    var myShowOpenOnly = this.model.get('parameters').parameters.showOpenOnly
    myShowOpenOnly = !myShowOpenOnly
    this.model.get('parameters').parameters.showOpenOnly = myShowOpenOnly
    this.model.get('parameters').parameters.page = 0
    var page = myApp.ent.config.get('page')
    if (page === 'myBidsLand') {
      myApp.vent.trigger('ui:notification', { text: 'We\'ve not yet figured out how to do this', level: 'warning' })
    } else {
      myApp.vent.trigger('layoutcontrol:toggle:open', { showOpenOnly: myShowOpenOnly })
    }
    return false
  },
  handleSubmit: function (e) {
    e.preventDefault()
    return false
  },
  handleSaleInfoInPageShow: function (e) {
    e.preventDefault()
    var showFilters = myApp.ent.user.getPreference('showFilters', { value: false })
    var activeButton = $(e.currentTarget)

    if (showFilters && myApp.ent.user.getPreference('saleInfoActive') === 'active') {
      showFilters = false
      activeButton.removeClass('active')
    } else {
      showFilters = true
      activeButton.addClass('active')
      myApp.ent.user.setPreference('saleInfoActive', 'active')
      myApp.ent.user.setPreference('filtersActive', '')
    }
    myApp.ent.user.setPreference('showFilters', showFilters)
    myApp.vent.trigger('landing:display', { 'showFilters': showFilters })
    return false
  },
  handleFiltersShow: function (e) {
    e.preventDefault()
    var showFilters = myApp.ent.user.getPreference('showFilters', { value: false })
    var activeButton = $(e.currentTarget)

    if (showFilters && myApp.ent.user.getPreference('filtersActive') === 'active') {
      showFilters = false
      activeButton.removeClass('active')
    } else {
      showFilters = true
      activeButton.addClass('active')
      myApp.ent.user.setPreference('saleInfoActive', '')
      myApp.ent.user.setPreference('filtersActive', 'active')
    }
    myApp.ent.user.setPreference('showFilters', showFilters)
    myApp.vent.trigger('landing:display', { 'showFilters': showFilters })
    return false
  },
  handleSaleInfoShow: function (e) {
    e.preventDefault()
    myApp.vent.trigger('saleinfo:display', { saleInfoModel: myApp.ent.saleInfo })
    return false
  },
  handleChangeLayout: function (e) {
    e.preventDefault()
    var layout = $(e.currentTarget).data('layout')
    myApp.ent.user.setPreference('layoutForBidding', layout)
    myApp.vent.trigger('layoutcontrol:display')
    return false
  },
  handleChangeFilter: function (e) {
    e.preventDefault()
    var filterId = $(e.currentTarget).data('filter')
    if (filterId === myApp.ent.user.getPreference('filterId')) {
      myApp.ent.user.setPreference('filterId', 0)
    } else {
      myApp.ent.user.setPreference('filterId', filterId)
    }
    myApp.vent.trigger('layoutcontrol:display')
    return false
  }
})
