window.apiConfiguration = {
  'content': 1,
  'marketplace': 1,
  'models': 1,
  'myBids': 1,
  'reference': 1,
  'relatedItems': 1,
  'search': 1,
  'timed/autobid': 1,
  'timed/bid': 1,
  'timed/buyNow': 1,
  'timed/contactSeller': 1,
  'timed/items': 1,
  'timed/makeOffer': 1,
  'timed/multiBuy': 1,
  'timed/mystate': 1,
  'timed/mystates': 1,
  'timed/tender': 1,
  'timed/tenderPct': 1,
  'timed/transfer': 1,
  'timed/watching': 1,
  'typeahead/item': 1,
  'webcast2/placeBid': 2
}
