PageApp.Ent.Timed2PrimaryHelper = PageApp.Ent.Timed2BaseHelper.extend({
  getNumberOrRef: function (attributes) {
    var numberOrRef = 'Ref ' + attributes.id
    if (!attributes.itemType.marketplace) {
      if (attributes.itemType.bulkBuyItem) {
        numberOrRef = myApp.reqres.request('i16:getString', 'ItemCodes_BULK_BUY_ITEM_DISPLAY_VALUE') + ' ' + attributes.lotNumber
      } else if (attributes.itemType.supportsBidding) {
        numberOrRef = myApp.reqres.request('i16:getString', 'ItemCodes_LOT_DISPLAY_VALUE') + ' ' + attributes.lotNumber
      } else {
        numberOrRef = myApp.reqres.request('i16:getString', 'ItemCodes_LISTING_DISPLAY_VALUE') + ' ' + attributes.lotNumber
      }
    }
    return numberOrRef
  },
  getBidIncrementWithCurrency: function (attributes) {
    return attributes.localeSensitive.currency.symbol + myApp.utils.types.toFormattedNumber(attributes.timedBiddingInfo.increment, myApp.ent.user.attributes.thousandsSeparator)
  },
  getDisplayPriceWithCurrency: function (attributes) {
    return attributes.localeSensitive.currency.symbol + myApp.utils.types.toFormattedNumber(attributes.timedBiddingInfo.displayPrice, myApp.ent.user.attributes.thousandsSeparator)
  },
  getMinimumOfferOrBuyNowPriceWithCurrency: function (attributes) {
    return attributes.localeSensitive.currency.symbol + myApp.utils.types.toFormattedNumber(attributes.timedBiddingInfo.minimumOfferOrBuyNowPrice, myApp.ent.user.attributes.thousandsSeparator)
  },
  getHighBidMessage: function (attributes) {
    if (attributes.timedBiddingInfo.highestBid && attributes.timedBiddingInfo.highestBid > 0) {
      return attributes.localeSensitive.currency.symbol + myApp.utils.types.toFormattedNumber(attributes.timedBiddingInfo.highestBid, myApp.ent.user.attributes.thousandsSeparator)
    } else {
      if (attributes.timedBiddingInfo.openingBid && attributes.timedBiddingInfo.openingBid > 0) {
        return attributes.localeSensitive.currency.symbol + myApp.utils.types.toFormattedNumber(attributes.timedBiddingInfo.openingBid, myApp.ent.user.attributes.thousandsSeparator)
      }
      return '-'
    }
  },
  getHighestAutoBidFormatted: function (attributes) {
    try {
      var amount = attributes.localeSensitive.currency.symbol + myApp.utils.types.toFormattedNumber(attributes.timedUserInfo.highestAutoBid, myApp.ent.user.attributes.thousandsSeparator)
      var msg = myApp.reqres.request('i16:getString', 'ItemMessageStatus_BIDDING_BAR_YOUR_HIGHEST_AUTO_BID')
      return msg.replace('{0}', amount)
    } catch (err) {
      console.log(err)
      return ''
    }
  },
  getAmountNextBidFormattedWithCurrency: function (attributes) {
    var amount = this.getAmountNextBid(attributes)
    return attributes.localeSensitive.currency.symbol + myApp.utils.types.toFormattedNumber(amount, myApp.ent.user.attributes.thousandsSeparator)
  },
  getNumberOfBids: function (attributes) {
    if (attributes.timedBiddingInfo.numberOfBids && attributes.timedBiddingInfo.numberOfBids > 0) {
      return attributes.timedBiddingInfo.numberOfBids
    }
    return 0
  },
  isHighestBidder: function (attributes) {
    return myApp.ent.user.isLoggedIn() && attributes.timedBiddingInfo.highestBidUID === myApp.ent.user.get('id')
  },
  isBidding: function (attributes) {
    if (myApp.ent.user.isNotLoggedIn()) return false
    if (attributes.timedBiddingInfo.bidUIDs) {
      for (var key in attributes.timedBiddingInfo.bidUIDs) {
        if (attributes.timedBiddingInfo.bidUIDs[key] === myApp.ent.user.get('id')) {
          return true
        }
      }
    }
    return false
  },
  isAutoBidding: function (attributes) {
    if (myApp.ent.user.isNotLoggedIn()) return false
    if (attributes.timedUserInfo.highestAutoBid && attributes.timedUserInfo.highestAutoBid > 0) {
      return true
    }
    return false
  },
  isOffering: function (attributes) {
    if (myApp.ent.user.isNotLoggedIn()) return false
    if (attributes.timedUserInfo.offerModels) {
      for (var key in attributes.timedUserInfo.offerModels) {
        if (attributes.timedUserInfo.offerModels[key].userId === myApp.ent.user.get('id')) return true
      }
    }
    return false
  },
  getSashModel: function (attributes) {
    var model = {}
    if (!myApp.ent.timedHelper.isClosed(attributes) && attributes.recentlyAdded) {
      model.showSash = true
      model.sashName = 'recently-added'
    }
    if (myApp.ent.timedHelper.isClosed(attributes) && myApp.ent.timedHelper.isWhollyPurchased(attributes)) {
      var handle
      if (attributes.timedBiddingInfo.makeOfferEnabled) {
        handle = 'make-offer'
      } else if (attributes.timedBiddingInfo.buyNowEnabled) {
        handle = 'buy-now'
      }
      if (myApp.ent.user.isNotLoggedIn()) {
        model.showSash = true
        model.sashName = handle
      } else if (!myApp.ent.timedHelper.isOffering(attributes)) {
        if (!myApp.ent.timedHelper.isBidding(attributes)) {
          model.showSash = true
          model.sashName = handle
        }
      }
    }
    return model
  },
  getAmountNextBid: function (attributes) {
    var current = attributes.timedBiddingInfo.highestBid
    if (!current && attributes.timedBiddingInfo.openingBid) {
      return attributes.timedBiddingInfo.openingBid
    }
    if (!current) {
      current = 0
    }
    return current + attributes.timedBiddingInfo.increment
  },
  getBidTypeMessage: function (attributes) {
    var bidTypeMessage
    if (attributes.timedBiddingInfo.biddingTypePerUnit && attributes.timedBiddingInfo.numberOfUnits > 1) {
      bidTypeMessage = myApp.reqres.request('i16:getString', 'ItemCodes_ITEM_BIDDING_BID_AMOUNT_PER_UNIT')
      bidTypeMessage = bidTypeMessage.replace('{0}', attributes.timedBiddingInfo.numberOfUnits)
    }
    return bidTypeMessage
  },
  getHighBidAmountCalculation: function (attributes) {
    var highBidAmountCalculation
    var condition1 = (attributes.timedBiddingInfo.biddingTypePerUnit && attributes.timedBiddingInfo.numberOfUnits > 1)
    var condition2 = (attributes.timedBiddingInfo.biddingTypePerLot && attributes.timedBiddingInfo.itemCount > 1)
    var amount = attributes.timedBiddingInfo.highestBid
    if (amount === 0) {
      amount = attributes.timedBiddingInfo.openingBid
    }
    if (amount > 0 && (condition1 || condition2)) {
      var currencySymbol = attributes.localeSensitive.currency.symbol
      var amountFormattedWithCurrency = currencySymbol + myApp.utils.types.toFormattedNumber(amount, myApp.ent.user.attributes.thousandsSeparator)
      var units
      if (attributes.timedBiddingInfo.biddingTypePerUnit) {
        units = attributes.timedBiddingInfo.numberOfUnits
      } else {
        units = attributes.timedBiddingInfo.itemCount
      }

      var totalAmountFormattedWithCurrency = currencySymbol + myApp.utils.types.toFormattedNumber(amount * units, myApp.ent.user.attributes.thousandsSeparator)
      highBidAmountCalculation = '(' + units + ' x ' + amountFormattedWithCurrency + ' = ' + totalAmountFormattedWithCurrency + ')'
    }
    return highBidAmountCalculation
  },
  getUserStatus: function (attributes) {
    var userStatus = new PageApp.Ent.BasicEntity()
    userStatus.set('classes', 'alert-warning')
    var registrant = attributes.registrant

    if (registrant) {
      // Replace the statusLabel on the registrant
      // (if the admin webapp has created the registrant object then this will be in English which may not be the user's selected language)
      registrant.statusLabel = myApp.reqres.request('i16:getString', 'AuctionRegistrantStatus_' + registrant.statusName)
    }

    // if one of these statuses then display a user message
    var userMessages = [1, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 23, 24, 25]
    var sellingStatus = this.getSellingStatus(attributes)
    userStatus.set('userMessage', (userMessages.indexOf(sellingStatus) > -1)) // true if sellingStatus is contained in userMessages

    userStatus.set('id', attributes.id)
    userStatus.set('sellingStatus', sellingStatus)
    this.setStatusMessageIcon(userStatus, attributes)

    if (userStatus.get('userMessage')) {
      if (this.isLosing(sellingStatus) || this.isLost(sellingStatus)) {
        userStatus.set('classes', 'alert-danger')
      } else if (this.isWinning(attributes) || this.isWon(attributes)) {
        userStatus.set('classes', 'alert-success')
      } else {
        userStatus.set('classes', 'alert-warning')
      }
    }

    if (this.isWithdrawn(attributes) || this.isWithdrawnEnded(attributes)) {
      if (!myApp.ent.user.isLoggedIn()) {
        userStatus.set('userStatusMessage', myApp.reqres.request('i16:getString', 'ItemMessageStatus_NOT_LOGGED_IN'))
      } else {
        userStatus.set('userStatusMessage', myApp.reqres.request('i16:getString', 'ItemMessageStatus_ITEM_WITHDRAWN'))
      }
    } else if (this.isClosed(attributes)) {
      if (!myApp.ent.user.isLoggedIn()) {
        userStatus.set('userStatusMessage', myApp.reqres.request('i16:getString', 'ItemMessageStatus_NOT_LOGGED_IN'))
      }
    } else {
      if (!myApp.ent.user.isLoggedIn()) {
        userStatus.set('userStatusMessage', myApp.reqres.request('i16:getString', 'ItemMessageStatus_NOT_LOGGED_IN'))
      } else {
        if (!registrant || registrant.id < 0) {
          userStatus.set('userStatusMessage', myApp.reqres.request('i16:getString', 'ItemMessageStatus_ITEM_REGISTER_TO_DO_SOMETHING'))
        } else {
          if (registrant.status === 2) {
            if (!userStatus.get('userMessage')) {
              userStatus.set('classes', 'alert-warning')
              userStatus.set('userStatusMessage', registrant.statusLabel)
            }
          } else {
            userStatus.set('userMessage', true)
            userStatus.set('userStatusMessage', registrant.statusLabel)
            userStatus.set('classes', 'alert-danger')
            if (registrant.status === 3 || registrant.status === 6 || registrant.status === 7) {
              userStatus.set('icon', 'cross.png')
            } else if (registrant.status === 4 || registrant.status === 1) {
              userStatus.set('icon', 'alert.png')
              userStatus.set('classes', 'alert-warning')
            }
          }
        }
      }
    }

    if (sellingStatus === 1) {
      var percent = (attributes.timedBiddingInfo.highestBid / attributes.timedBiddingInfo.reserve) * 100
      userStatus.set('percentOfReserve', percent)
      userStatus.set('showReserveIndicator', true)
    }

    return userStatus.attributes
  },
  setStatusMessageIcon: function (userStatus, attributes) {
    var msg
    var offer
    if (userStatus.get('sellingStatus') === 1) {
      userStatus.set('icon', 'alert.png')
      userStatus.set('userStatusMessage', myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_BAR_HIGHEST_BID_LOWER_THAN_RESERVE'))
    } else if (userStatus.get('sellingStatus') === 4) {
      userStatus.set('icon', 'tick.png')
      userStatus.set('userStatusMessage', myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_BAR_YOUR_BID_CURRENTLY_HIGHEST'))
    } else if (userStatus.get('sellingStatus') === 5) {
      userStatus.set('icon', 'cross.png')
      userStatus.set('userStatusMessage', myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_BAR_AUTOBID_HAS_OUTBID_YOU'))
    } else if (userStatus.get('sellingStatus') === 6) {
      userStatus.set('icon', 'cross.png')
      userStatus.set('userStatusMessage', myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_BAR_ANOTHER_BID_HAS_OUTBID_YOU'))
    } else if (userStatus.get('sellingStatus') === 7) {
      userStatus.set('icon', 'alert.png')
      userStatus.set('userStatusMessage', myApp.reqres.request('i16:getString', 'Permission to bid has been withdrawn'))
    } else if (userStatus.get('sellingStatus') === 8) {
      userStatus.set('icon', 'tick.png')
      userStatus.set('userStatusMessage', myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_BAR_YOUR_BID_WAS_HIGHEST'))
    } else if (userStatus.get('sellingStatus') === 9) {
      userStatus.set('icon', 'cross.png')
      userStatus.set('userStatusMessage', myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_BAR_YOUR_BID_WAS_NOT_HIGH_ENOUGH'))
    } else if (userStatus.get('sellingStatus') === 10) {
      userStatus.set('icon', 'alert.png')
      if (attributes.itemType.listingItem) {
        userStatus.set('userStatusMessage', myApp.reqres.request('i16:getString', 'ItemMessageStatus_LISTING_CLOSED_MESSAGE_GENERAL'))
      } else {
        userStatus.set('userStatusMessage', myApp.reqres.request('i16:getString', 'ItemMessageStatus_LOT_CLOSED_MESSAGE'))
      }
    } else if (userStatus.get('sellingStatus') === 11 || userStatus.get('sellingStatus') === 13) {
      userStatus.set('icon', 'cross.png')
      userStatus.set('userStatusMessage', myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_BAR_YOUR_OFFER_WAS_NOT_HIGH_ENOUGH'))
    } else if (userStatus.get('sellingStatus') === 12) {
      userStatus.set('icon', 'tick.png')
      offer = myApp.ent.purchaseEntity.getHighestAcceptedOffer(attributes)
      msg = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_BAR_YOUR_OFFER_OF')
      msg += ' ' + attributes.localeSensitive.currency.symbol + myApp.utils.types.toFormattedNumber(offer.amount, myApp.ent.user.attributes.thousandsSeparator)
      msg += ' ' + myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_BAR_ON_THIS_ITEM_WAS_ACCEPTED')
      userStatus.set('userStatusMessage', msg)
    } else if (userStatus.get('sellingStatus') === 14) {
      userStatus.set('icon', 'cross.png')
      userStatus.set('userStatusMessage', myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_BAR_SOLD_BUY_NOW'))
    } else if (userStatus.get('sellingStatus') === 15) {
      userStatus.set('icon', 'tick.png')
      msg = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_BAR_YOUR_PURCHASED_VIA_BUY_NOW_FOR')
      msg += ' ' + attributes.localeSensitive.currency.symbol + myApp.utils.types.toFormattedNumber(attributes.timedBiddingInfo.minimumOfferOrBuyNowPrice, myApp.ent.user.attributes.thousandsSeparator)
      userStatus.set('userStatusMessage', msg)
    } else if (userStatus.get('sellingStatus') === 16) {
      userStatus.set('icon', 'cross.png')
      userStatus.set('userStatusMessage', myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_BAR_PURCHASE_PRICE_EXCEEDED'))
    } else if (userStatus.get('sellingStatus') === 17) {
      userStatus.set('icon', 'tick.png')
      userStatus.set('userStatusMessage', myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_BAR_YOU_HAVE_ACCEPTED_OFFERS'))
    } else if (userStatus.get('sellingStatus') === 18) {
      userStatus.set('icon', 'cross.png')
      userStatus.set('userStatusMessage', myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_BAR_YOUR_OFFERS_WERE_NOT_HIGH_ENOUGH'))
    } else if (userStatus.get('sellingStatus') === 19) {
      userStatus.set('icon', 'tick.png')
      userStatus.set('userStatusMessage', myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_BAR_YOU_HAVE_ACCEPTED_OFFERS_2'))
    } else if (userStatus.get('sellingStatus') === 21) {
      userStatus.set('icon', 'tick.png')
      userStatus.set('userStatusMessage', myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_BAR_TRANSFERRED_BY_YOU'))
    } else if (userStatus.get('sellingStatus') === 22) {
      userStatus.set('icon', 'cross.png')
      userStatus.set('userStatusMessage', myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_BAR_TRANSFERRED'))
    } else if (userStatus.get('sellingStatus') === 23) {
      var isTenderPct = attributes.timedBiddingInfo.tenderPctEnabled
      var currency = attributes.localeSensitive.currency.symbol
      var unitOfMeasurement = attributes.timedBiddingInfo.unitOfMeasurement
      userStatus.set('icon', 'tick.png')
      offer = myApp.ent.purchaseEntity.getHighestAcceptedOffer(attributes)
      var offerMsg = myApp.utils.types.toFormattedNumber(offer.amount, myApp.ent.user.attributes.thousandsSeparator)
      if (isTenderPct) {
        offerMsg = offerMsg + '% ' + unitOfMeasurement
      } else {
        offerMsg = currency + offerMsg + ' ' + myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_UNIT_OF_MEASUREMENT_PER') + ' ' + unitOfMeasurement
      }
      msg = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_BAR_YOUR_TENDER_ACCEPTED')
      msg = msg.replace('{}', offerMsg)
      userStatus.set('userStatusMessage', msg)
    } else if (userStatus.get('sellingStatus') === 24) {
      userStatus.set('icon', 'cross.png')
      userStatus.set('userStatusMessage', myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_BAR_OTHER_TENDER_ACCEPTED'))
    } else if (userStatus.get('sellingStatus') === 25) {
      userStatus.set('icon', 'cross.png')
      userStatus.set('userStatusMessage', myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_BAR_TENDER_ACCEPTED'))
    }
  },

  getSellingStatus: function (attributes) {
    if (myApp.ent.user.isNotLoggedIn()) return 0
    if (attributes.itemType.bulkBuyItem) {
      return this.getSellingStatusBulkBuy(attributes)
    } else if (attributes.itemType.listingItem) {
      return this.getSellingStatusListing(attributes)
    } else {
      return this.getSellingStatusLot(attributes)
    }
  },
  getSellingStatusListing: function (attributes) {
    var status = this.attributes.userStatuses.NOT_WINNING
    var loggedIn = myApp.ent.user.isLoggedIn()
    var sessionUserIsAcceptedOfferer = myApp.ent.purchaseEntity.getHighestAcceptedOffer(attributes)
    var sessionUserHasMadeOffer = myApp.ent.purchaseEntity.getNumberOfOffers(attributes) > 0
    if (this.isClosed(attributes)) {
      status = this.attributes.userStatuses.ITEM_CLOSED
      if (loggedIn) {
        if (attributes.timedBiddingInfo.offerAccepted) {
          if (sessionUserHasMadeOffer) {
            if (sessionUserIsAcceptedOfferer) {
              if (attributes.timedBiddingInfo.makeOfferEnabled) {
                // session user's offer purchased the item
                status = this.attributes.userStatuses.ITEM_WON_BY_MAKE_OFFER
              } else if (attributes.timedBiddingInfo.buyNowEnabled) {
                // session user purchased the item with Buy Now
                status = this.attributes.userStatuses.ITEM_WON_BY_BUY_NOW
              } else if (attributes.timedBiddingInfo.tenderEnabled || attributes.timedBiddingInfo.tenderPctEnabled) {
                // session user's tender was successful
                status = this.attributes.userStatuses.ITEM_WON_BY_TENDER_OFFER
              } else {
                // session user transferred the item
                status = this.attributes.userStatuses.ITEM_WON_BY_TRANSFER
              }
            } else {
              if (attributes.timedBiddingInfo.makeOfferEnabled) {
                // other user's offer purchased the item
                status = this.attributes.userStatuses.ITEM_LOST_TO_MAKE_OFFER
              } else if (attributes.timedBiddingInfo.buyNowEnabled) {
                // other user purchased the item with Buy Now
                status = this.attributes.userStatuses.ITEM_LOST_TO_BUY_NOW
              } else if (attributes.timedBiddingInfo.tenderEnabled || attributes.timedBiddingInfo.tenderPctEnabled) {
                // session user's tender was successful
                status = this.attributes.userStatuses.ITEM_LOST_TO_TENDER_OFFER
              } else {
                // other user transferred the item
                status = this.attributes.userStatuses.ITEM_LOST_TO_TRANSFER
              }
            }
          }
        }
      }
    }
    return status
  },
  getSellingStatusBulkBuy: function (attributes) {
    var loggedIn = myApp.ent.user.isLoggedIn()
    var status
    var sessionUserIsAcceptedOfferer = myApp.ent.purchaseEntity.getHighestAcceptedOffer(attributes)
    if (this.isClosed(attributes)) {
      if (loggedIn) {
        if (sessionUserIsAcceptedOfferer) {
          status = this.attributes.userStatuses.ITEM_CLOSED_USER_HAS_ACCEPTED_OFFERS
        } else if (this.isOffering(attributes)) {
          status = this.attributes.userStatuses.ITEM_CLOSED_USER_HAS_NO_ACCEPTED_OFFERS
        } else {
          status = this.attributes.userStatuses.ITEM_CLOSED
        }
      } else {
        status = this.attributes.userStatuses.ITEM_CLOSED
      }
    } else {
      if (loggedIn) {
        if (sessionUserIsAcceptedOfferer) {
          status = this.attributes.userStatuses.ITEM_OPEN_USER_HAS_ACCEPTED_OFFERS
        } else {
          status = this.attributes.userStatuses.ITEM_OPEN_USER_HAS_NO_ACCEPTED_OFFERS
        }
      } else {
        status = this.attributes.userStatuses.NOT_WINNING
      }
    }
    return status
  },
  getSellingStatusLot: function (attributes) {
    var status = this.attributes.userStatuses.NOT_WINNING
    var userId = myApp.ent.user.get('id')
    var loggedIn = myApp.ent.user.isLoggedIn()
    var webcasty = attributes.itemType.webcasty
    var reserve = attributes.timedBiddingInfo.reserve
    var sessionUserIsHighestBidder = (attributes.timedBiddingInfo.highestBidUID === userId)
    var sessionUserIsAcceptedOfferer = myApp.ent.purchaseEntity.getHighestAcceptedOffer(attributes)

    if (this.isClosed(attributes)) {
      if (loggedIn) {
        var offering = myApp.ent.timedHelper.isOffering(attributes)
        if (attributes.timedBiddingInfo.offerAccepted && offering) {
          if (sessionUserIsAcceptedOfferer) {
            if (attributes.timedBiddingInfo.makeOfferEnabled) {
              // session user's offer purchased the item
              status = this.attributes.userStatuses.ITEM_WON_BY_MAKE_OFFER
            } else {
              // session user purchased the item with Buy Now
              status = this.attributes.userStatuses.ITEM_WON_BY_BUY_NOW
            }
          } else {
            if (attributes.timedBiddingInfo.makeOfferEnabled) {
              // other user's offer purchased the item
              status = this.attributes.userStatuses.ITEM_LOST_TO_MAKE_OFFER
            } else {
              // other user purchased the item with Buy Now
              status = this.attributes.userStatuses.ITEM_LOST_TO_BUY_NOW
            }
          }
        } else if (attributes.timedBiddingInfo.offerAccepted && !offering) {
          if (attributes.timedBiddingInfo.makeOfferEnabled) {
            if (this.isBidding(attributes)) {
              status = this.attributes.userStatuses.ITEM_LOST
            } else if (this.isOffering(attributes)) {
              status = this.attributes.userStatuses.ITEM_LOST_TO_MAKE_OFFER_NO_BIDS
            } else {
              status = this.attributes.userStatuses.ITEM_CLOSED
            }
          } else {
            status = this.attributes.userStatuses.ITEM_LOST_TO_BUY_NOW
          }
        } else {
          if (attributes.timedBiddingInfo.highestBid > 0) {
            if (sessionUserIsHighestBidder) {
              // session user won the bidding.
              // For webcast2, the reserveprice stores the asking price for the lot so will always be greater than the highest bid
              // Therefore a bidder on a webcast auction will win despite not reaching the 'reserveprice'
              if (attributes.timedBiddingInfo.highestBid >= reserve || webcasty) {
                status = this.attributes.userStatuses.ITEM_WON
              } else {
                // session user lost the bidding to the reserve.
                status = this.attributes.userStatuses.ITEM_LOST
              }
            } else if (this.isBidding(attributes)) {
              // session user made a bid but not the highest
              status = this.attributes.userStatuses.ITEM_LOST
            } else if (this.isAutoBidding(attributes)) {
              // session user made a bid but not the highest
              status = this.attributes.userStatuses.ITEM_LOST
            } else if (this.isOffering(attributes)) {
              if (attributes.timedBiddingInfo.makeOfferEnabled) {
                status = this.attributes.userStatuses.ITEM_LOST_TO_MAKE_OFFER_NO_BIDS
              } else {
                status = this.attributes.userStatuses.ITEM_LOST_TO_BUY_NOW_NO_BIDS
              }
            } else {
              status = this.attributes.userStatuses.ITEM_CLOSED
            }
          } else if (this.isOffering(attributes)) {
            if (attributes.timedBiddingInfo.makeOfferEnabled) {
              status = this.attributes.userStatuses.ITEM_LOST_TO_MAKE_OFFER_NO_BIDS
            } else {
              status = this.attributes.userStatuses.ITEM_LOST_TO_BUY_NOW_NO_BIDS
            }
          } else {
            status = this.attributes.userStatuses.ITEM_CLOSED
          }
        }
      } else {
        status = this.attributes.userStatuses.ITEM_CLOSED
      }
    } else {
      // Lot is still open

      if (loggedIn) {
        if (sessionUserIsHighestBidder) {
          /*
          * Webcast2 is now using the reserve price to hold the asking price (more than it was previously). Previously in most
          * cases it would be zero so you would never see the under reserve message but now you potentially could.
          */
          if (attributes.timedBiddingInfo.highestBid < reserve && !webcasty) {
            status = this.attributes.userStatuses.UNDER_RESERVE
          } else {
            status = this.attributes.userStatuses.ITEM_OPEN_USER_HAS_WINNING_BID
          }
        } else {
          if (this.isBidding(attributes)) {
            // highestBid won't be null since the sessionUser has made a bid (so at least one exists)
            if (attributes.timedBiddingInfo.highestBid < reserve && !webcasty) {
              status = this.attributes.userStatuses.UNDER_RESERVE
            } else {
              if (attributes.timedBiddingInfo.highestBidHasAutobid) {
                status = this.attributes.userStatuses.OUTBID_BY_AUTOBID
              } else {
                status = this.attributes.userStatuses.OUTBID_BY_ANOTHER_BID
              }
            }
          } else if (this.isAutoBidding(attributes)) {
            // checking for losing autobids which have not triggered any bids..
            if (attributes.timedBiddingInfo.highestBidHasAutobid) {
              if (attributes.timedBiddingInfo.highestBid > attributes.timedUserInfo.highestAutoBid) {
                status = this.attributes.userStatuses.OUTBID_BY_AUTOBID
              }
            } else {
              if (attributes.timedBiddingInfo.highestBid && attributes.timedBiddingInfo.highestBid > 0) {
                status = this.attributes.userStatuses.OUTBID_BY_ANOTHER_BID
              }
            }
          }
        }
      }
      // else leave as NOT_WINNING
    }
    return status
  }

})
